<template>
  <div class="container">
    <template v-if="allows_crud">
      <div class="row left m-1">
        <b-button
          @click="$bvModal.show('modal-create-admission-profile')"
          size="sm"
          class="my-1"
          >Crear Perfil de Ingreso</b-button
        >
      </div>
      <div class="row left m-1">
        <b-form-select
          :options="admission_profiles"
          v-model="selected_admission_profile_id"
          value-field="id"
          text-field="name"
          size="sm"
        ></b-form-select>
      </div>
      <div class="row left m-1">
        <b-button
          v-if="selected_admission_profile_id"
          @click="$bvModal.show('modal-create-survey')"
          size="sm"
          class="my-1"
          >Crear Encuesta</b-button
        >
      </div>
    </template>
    <template v-else>
      {{ admission_profile ? admission_profile.name : "" }}
    </template>

    <template v-if="admission_profile">
      <div v-if="selectedSurveys.length > 0">
        <b-tabs content-class="mt-3">
          <b-tab
            :title="survey.title"
            v-for="survey in selectedSurveys"
            :key="survey.id"
          >
            <Survey
              :Survey="survey"
              :allows_crud="allows_crud"
              @updated="slotUpdatedSurvey"
            ></Survey>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <!-- Modals -->
    <b-modal
      id="modal-create-admission-profile"
      title="Crear Perfil de Ingreso"
      hide-footer
    >
      <AdmissionProfileForm
        @created="slotProfileCreated"
      ></AdmissionProfileForm>
    </b-modal>
    <b-modal id="modal-create-survey" title="Crear Encuesta" hide-footer>
      <SurveyForm @created="slotSurveyCreated"></SurveyForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "AdmissionProfileView",
  components: {
    Survey: () => import("./Surveys/Survey"),
    AdmissionProfileForm: () => import("./AdmissionProfileForm"),
    SurveyForm: () => import("./Surveys/SurveyForm"),
  },
  data() {
    return {
      admission_profile: null,
      admission_profiles: [],
      selected_admission_profile_id: null,
      surveys: [],
      allows_crud: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    selectedSurveys() {
      let list = [];
      this.admission_profile.surveys.forEach((survey_id) => {
        const survey = this.surveys.find((x) => x.id == survey_id);
        if (survey) {
          list.push(survey);
        }
      });
      return list;
    },
  },
  methods: {
    fetchProfile() {
      this.$restful
        .Get(`/cideu/admission-profile/?student=${this.user.id}`)
        .then((response) => {
          this.admission_profile = response;
        });
    },
    fetchProfiles() {
      this.$restful
        .Get(
          `/cideu/admission-profile/?institution=${this.user.school}`
        )
        .then((response) => {
          this.admission_profiles = response;
        });
    },
    patchProfile(item) {
      this.$restful
        .Patch(
          `/cideu/admission-profile/${this.selected_admission_profile_id}/`,
          item
        )
        .then((response) => {
          this.admission_profile = response;
          const index = this.admission_profiles.findIndex(
            (x) => x.id == response.id
          );
          if (index != -1) {
            this.admission_profiles.splice(index, 1, response);
          }
        });
    },
    slotProfileCreated(profile) {
      this.admission_profiles.push(profile);
      this.$bvModal.hide("modal-create-admission-profile");
    },
    slotSurveyCreated(survey) {
      let surveys = this.admission_profile.surveys;
      surveys.push(survey.id);
      this.surveys.push(survey);
      this.patchProfile({ surveys: surveys });
      this.$bvModal.hide("modal-create-survey");
    },
    slotUpdatedSurvey(survey) {
      const index = this.surveys.findIndex((x) => x.id == survey.id);
      if (index != -1) this.surveys.splice(index, 1, survey);
      else this.surveys.push(survey);
    },
  },
  watch: {
    selected_admission_profile_id() {
      const instance = this.admission_profiles.find(
        (x) => x.id == this.selected_admission_profile_id
      );
      if (instance) this.admission_profile = instance;
      this.admission_profile.surveys.forEach((survey_id) => {
        const survey = this.surveys.find((x) => x.id == survey_id);
        if (!survey) {
          this.$restful.Get(`/cideu/survey/${survey_id}/`).then((response) => {
            this.surveys.push(response);
          });
        }
      });
    },
  },
  created() {
    if (this.user.is_superuser) {
      this.fetchProfiles();
      this.allows_crud = true;
    } else {
      this.fetchProfile();
    }
    this.$store.dispatch(names.FETCH_PERIODS);
  },
};
</script>

<style scoped>
.survey-card {
  background-color: rgb(245, 245, 245);
  border: 1px solid var(--primary-color);
}
</style>